import React from 'react';
import styled from 'styled-components';

const VideoWrapper = styled.div`
  position: relative;
  background: ${props => props.background};
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  padding-top: 25px;
  height: 0;
  border-radius: 15px; /* Curvy edges */

  @media (min-width: 768px) { /* Adjust breakpoint as needed */
    width: 100%; /* Larger on desktop */
    margin: auto; /* Centering if needed */
  }

  @media (max-width: 767px) {
    width: 100%; /* Full width on mobile */
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px; /* Curvy edges for iframe */
  }
`;

export default ({ title = 'Embeded Video', url, background = "black", className = "video" }) => {
  return (
    <VideoWrapper className={className} background={background}>
      <iframe
        title={title}
        src={url}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        frameBorder="0"
      />
    </VideoWrapper>
  );
};
