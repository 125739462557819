import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from '../../images/dot-pattern.svg';
import emailjs from '@emailjs/browser';
import { useState } from 'react';
import { useRef } from 'react';
import AWS from 'aws-sdk';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-gray-700 text-white rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-5xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-white text-base font-medium tracking-wide border-b-2 py-2 text-white hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-300`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-xl`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-black rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`;

const FileInput = tw.input`cursor-pointer`;

export default () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);

  AWS.config.update({
    region: 'ap-southeast-2',
    credentials: new AWS.Credentials('AKIAU6GDVXORAASGYJOE', 'lApwBIgEp/Q86IgSCk9EoxfRNnfQ8pChhldAdq82'),
  });

  const s3 = new AWS.S3();

  const handleFileUploads = async (files) => {
    const uploadPromises = Array.from(files).map(async (file) => {
      const params = {
        Bucket: 'modaela',
        Key: `uploads/${Date.now()}-${file.name}`, // Adding Date.now() to make each key unique
        Body: file,
      };

      try {
        const { Location } = await s3.upload(params).promise();
        return Location; // URL of the uploaded file
      } catch (error) {
        console.error('Upload error:', error);
        return null;
      }
    });

    return Promise.all(uploadPromises);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fileInput = document.getElementById('file-input');
    const fileUrls = await handleFileUploads(fileInput.files);
    const emailMessage = `From: ${form.email}\nMessage: ${form.message}\n\nFiles: ${fileUrls.join('\n')}`;
    setLoading(true);
    emailjs
      .send(
        'service_p2g75ec',
        'template_ibemqia',
        {
          from_name: form.name,
          to_name: 'Modaela',
          from_email: form.email,
          to_email: 'harveyflorenciomaitem082096@gmail.com',
          message: emailMessage,
        },
        '-qNMv2lF751jHyKib'
      )
      .then(() => {
        setLoading(false);
        alert('Thank you. I will get back to you as soon as possible');
        setForm(
          {
            name: '',
            email: '',
            message: '',
          },
          (error) => {
            setLoading(false);
            console.log(error);
            alert('Something went wrong.');
          }
        );
      });
    setLoading(false);
  };

  return (
    <Container id="Contact">
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2>Ask for a Quote?</h2>
            <form ref={formRef} onSubmit={handleSubmit} action="#">
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Company Name</Label>
                    <Input
                      id="name-input"
                      type="text"
                      name="name"
                      value={form.name}
                      onChange={handleChange}
                      placeholder="E.g. John Doe"
                    />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input
                      id="email-input"
                      type="email"
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                      placeholder="E.g. john@mail.com"
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="name-input">Details</Label>
                    <TextArea
                      id="message-input"
                      name="message"
                      value={form.message}
                      onChange={handleChange}
                      placeholder="E.g. Details about your design"
                    />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="file-input">Upload Design</Label>
                    <FileInput id="file-input" type="file" name="file" multiple />
                  </InputContainer>
                </Column>
              </TwoColumn>

              <SubmitButton type="submit" value="Submit">
                Submit
              </SubmitButton>
            </form>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
