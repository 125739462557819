import React from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import Hero from 'components/hero/TwoColumnWithVideo.js';
import Features from 'components/features/ThreeColSimple.js';
import MainFeature from 'components/features/TwoColWithButton.js';
import MainFeature2 from 'components/features/TwoColSingleFeatureWithStats2.js';
import TabGrid from 'components/cards/TabCardGrid.js';
import Testimonial from 'components/testimonials/ThreeColumnWithProfileImage.js';
import DownloadApp from 'components/cta/DownloadApp.js';
import Footer from 'components/footers/FiveColumnWithInputForm.js';
import ContactUsForm from 'components/forms/SimpleContactUs.js';

import chefIconImageSrc from 'images/chef-icon.svg';
import celebrationIconImageSrc from 'images/celebration-icon.svg';
import shopIconImageSrc from 'images/shop-icon.svg';
import BackgroundAsImage from 'components/hero/BackgroundAsImage';
import ContactDetails from "components/cards/ThreeColContactDetails.js";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-[#b76e79] text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <BackgroundAsImage />
      <ContactDetails />
      <ContactUsForm />
{/* 
      <Hero
        heading={
          <>
            Crafting Elegance & Carrying Excellence <HighlightedText>By Filipino Artisans </HighlightedText>
          </>
        }
        description="At Modaela Bags Manufacturing, we specialize in meticulously crafting a wide range of bags, blending functionality with style to meet the diverse needs of our clients"
        imageSrc="/meraki.png"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Quote your designs"
        watchVideoButtonText="Contact Us!"
      /> */}
      <MainFeature
        subheading={<Subheading>Established Since 1996</Subheading>}
        heading={
          <>
            We've been serving for
            <wbr /> <HighlightedText>over 27 years.</HighlightedText>
          </>
        }
        description={
          <Description>
            "Modaela Bags Manufacturing," established in 1996, is a distinguished enterprise specializing in the production of a
            diverse array of bags. Our business is situated at 64 L. De Guzman Street, Concepcion Uno, Marikina City.
            <br />
            <br />
            We are committed to delivering exceptional quality and craftsmanship in every product we create. Catering to both
            local and international clientele, our expertise lies in designing and manufacturing bags that meet various needs and
            preferences. Our dedication to excellence and customer satisfaction has been the cornerstone of our success for over a
            decade.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc={'/established.jpg'}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}

      {/* <Features
        heading={
          <>
            Amazing <HighlightedText>Services.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: '230+ Locations',
            description: 'Lorem ipsum donor amet siti ceali placeholder text',
            url: 'https://google.com',
          },
          {
            imageSrc: chefIconImageSrc,
            title: 'Professional Chefs',
            description: 'Lorem ipsum donor amet siti ceali placeholder text',
            url: 'https://timerse.com',
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: 'Birthday Catering',
            description: 'Lorem ipsum donor amet siti ceali placeholder text',
            url: 'https://reddit.com',
          },
        ]}
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      /> */}

      <MainFeature2
        subheading={<Subheading>A Reputed Manufacturer</Subheading>}
        heading={
          <>
            Why <HighlightedText>Choose Us ?</HighlightedText>
          </>
        }
        statistics={[
          {
            key: 'Clients',
            value: '100+',
          },
          {
            key: 'Projects',
            value: '12000+',
          },
        ]}
        primaryButtonText="Contact Us Now!"
        primaryButtonUrl="https://www.facebook.com/photo/?fbid=7294001373945190&set=a.166733583338707m"
        imageInsideDiv={false}
        imageSrc="/whychooseus.png"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
        description="Select Modaela Bags Manufacturing for unparalleled craftsmanship, innovative design, and top-quality materials. Our commitment to excellence and personalized service ensures every bag we create meets your unique needs and elevates your brand, making us the ideal partner for all your bag manufacturing requirements."
      />
      <Testimonial
        subheading=""
        heading={
          <>
            Our <HighlightedText>Clients.</HighlightedText>
          </>
        }
      />
      
      <Footer />
    </AnimationRevealPage>
  );
};
