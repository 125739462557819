import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro'; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { SectionDescription } from 'components/misc/Typography.js';

import { ReactComponent as SvgDecoratorBlob3 } from 'images/svg-decorator-blob-3.svg';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw` flex-shrink-0`}
    img {
      ${tw`w-40 h-40`}// Adjust size as needed
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = 'Our Ordering Process',
  subheading = 'Quotation',
  description = 'Our ordering process is straightforward. Please follow these steps to receive the best pricing for your bag design. ',
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) title - the title of the card
   *  2) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      title: 'Step 1: Quotation',
      description:
        "Please send us your bag design. Attach a photo of the design, the bag's dimensions, and, if available, a sample photo of the materials you wish to use for the bag.",
      imageSrc: '/quotation.png',
    },
    {
      title: 'Step 2: Sampling',
      description:
        'For the sampling process, we first create one sample to ensure that your bag design meets your standards before moving forward with production.',
      imageSrc: '/sampling.png',
    },
    {
      title: 'Step 3: Production',
      description:
        'We ensure that the quality is maintained during bulk production. Additionally, we are committed to adhering to the agreed-upon delivery timeline between us and the client.',
      imageSrc: '/production.png',
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id='Pricing'>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <div className="imageContainer">
                <img src={card.imageSrc} alt={card.title} />
              </div>
              <span className="textContainer">
                <span className="title">{card.title || 'Fully Secure'}</span>
                <p className="description">
                  {card.description || 'Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud.'}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
