import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks, NavLink2 } from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-scroll";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 m-0 p-0 w-screen h-screen bg-center bg-cover`}
  background-image: ${({ bgImageUrl }) => `url(${bgImageUrl})`};
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default () => {

  const [bgImageUrl, setBgImageUrl] = useState(null); // Initial state is null

  useEffect(() => {
    // Simulate lazy loading by setting the image URL after component mounts
    setBgImageUrl("/bags.jpg");
  }, []);


  const openGmailCompose = () => {
    // Define the email details
    const email = 'modaelabagsmfg@gmail.com'; // Add recipient email here if any
    const subject = encodeURIComponent('Your Subject Here');
    const body = encodeURIComponent('Your Email Body Here');
    
    // Open Gmail compose window
    window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`, '_blank');
  };

  const navLinks = [
    <NavLinks key={1}>
      <NavLink to="about" smooth={true} duration={500}>
        About
      </NavLink>
      <NavLink to="#">
        Gallery
      </NavLink>
      <NavLink2 href="https://maps.app.goo.gl/dMX32HizPisnxdJX8" target="_blank">
        Locations
      </NavLink2>
      <NavLink to="Pricing" smooth={true} duration={500}>
        Pricing
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink onClick={openGmailCompose} smooth={true} duration={500}>
        Contact Us
      </PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container bgImageUrl={bgImageUrl}>
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            <Notification>Philippines' Number #1 Bag Manufacturer</Notification>
            <Heading>
              <span>Crafting Elegance & Carrying Excellence</span>
              <br />
              <SlantedBackground>By Filipino Artisans</SlantedBackground>
            </Heading>
            <PrimaryAction><Link to="Contact">Ask for A Quote</Link></PrimaryAction>
          </LeftColumn>
          <RightColumn>
            <StyledResponsiveVideoEmbed
              url="https://www.youtube.com/embed/aXh4asDLSuw?si=6nxOxVKpMwKA0pRS&autoplay=1&mute=1"
              background="transparent"
            />
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
