import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { ContentWithPaddingXl, Container } from 'components/misc/Layouts.js';
import { SectionHeading as Heading, Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-7.svg';
import { ReactComponent as SvgDecoratorBlob2 } from 'images/svg-decorator-blob-8.svg';

const Subheading = tw(SubheadingBase)`text-center`;
const TestimonialsRow = tw.div`flex flex-wrap justify-center items-stretch`;
const TestimonialContainer = tw.div`mt-16 w-full lg:w-1/3 px-4`;
const Testimonial = tw.div`text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-32 h-32 rounded-full border border-black`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;
const CustomerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`;
const LazyImage = styled.img.attrs({
  loading: "lazy"
})`${tw`w-32 h-32 rounded-full border border-black`}`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = 'Testimonials',
  heading = "Customer's Review",
  testimonials = [
    {
      imageSrc: '/eve-adam.jpg',
      quote:
        "Our collaboration with Modaela Bags Manufacturing for our exclusive fashion line was exceptional. Their expertise and craftsmanship perfectly captured our vision, creating bags that resonate with our brand ethos. Modaela's quality and attention to detail have been crucial to our line's success. We're grateful for their outstanding partnership.",
      customerName: 'Eve & Adam',
    },
    {
      imageSrc: '/merakilogo.jpg',
      quote:
        "Meraki Essentials is immensely impressed with Modaela Bags Manufacturing's quality and innovative designs. Their skill in embodying our brand essence into beautifully crafted bags has greatly contributed to our success. The Modaela team's professionalism and creativity have been outstanding, enhancing our brand image. We highly recommend Modaela for top-tier, customized bag solutions",
      customerName: 'Meraki Essentials',
    },
    {
      imageSrc: '/bayer.png',
      quote:
        "Bayer's partnership with Modaela Bags Manufacturing has significantly elevated our brand. Their bespoke bags, blending functionality and style, reflect our commitment to quality and innovation. Modaela's professionalism and meticulous attention to detail have set a new standard, making their bags a symbol of our brand's excellence",
      customerName: 'Bayer PH',
    },
    {
      imageSrc: '/ayalaland.png',
      quote:
        "Our collaboration with Modaela Bags Manufacturing for our exclusive fashion line was exceptional. Their expertise and craftsmanship perfectly captured our vision, creating bags that resonate with our brand ethos. Modaela's quality and attention to detail have been crucial to our line's success. We're grateful for their outstanding partnership.",
      customerName: 'Ayala Land',
    },
    {
      imageSrc: '/easypeazy.jpg',
      quote:
        "Meraki Essentials is immensely impressed with Modaela Bags Manufacturing's quality and innovative designs. Their skill in embodying our brand essence into beautifully crafted bags has greatly contributed to our success. The Modaela team's professionalism and creativity have been outstanding, enhancing our brand image. We highly recommend Modaela for top-tier, customized bag solutions",
      customerName: 'Easy Peazy',
    },
    {
      imageSrc: '/megaworld.jpg',
      quote:
        "Bayer's partnership with Modaela Bags Manufacturing has significantly elevated our brand. Their bespoke bags, blending functionality and style, reflect our commitment to quality and innovation. Modaela's professionalism and meticulous attention to detail have set a new standard, making their bags a symbol of our brand's excellence",
      customerName: 'Megaworld',
    },
    {
      imageSrc: '/kurbanglokal.jpg',
      quote:
        "Our collaboration with Modaela Bags Manufacturing for our exclusive fashion line was exceptional. Their expertise and craftsmanship perfectly captured our vision, creating bags that resonate with our brand ethos. Modaela's quality and attention to detail have been crucial to our line's success. We're grateful for their outstanding partnership.",
      customerName: 'Kurbang Lokal',
    },
    {
      imageSrc: '/creamsilk.jpg',
      quote:
        "Meraki Essentials is immensely impressed with Modaela Bags Manufacturing's quality and innovative designs. Their skill in embodying our brand essence into beautifully crafted bags has greatly contributed to our success. The Modaela team's professionalism and creativity have been outstanding, enhancing our brand image. We highly recommend Modaela for top-tier, customized bag solutions",
      customerName: 'Creamsilk',
    },
    {
      imageSrc: '/goodjuju.jpg',
      quote:
        "Bayer's partnership with Modaela Bags Manufacturing has significantly elevated our brand. Their bespoke bags, blending functionality and style, reflect our commitment to quality and innovation. Modaela's professionalism and meticulous attention to detail have set a new standard, making their bags a symbol of our brand's excellence",
      customerName: 'Good Juju',
    },
    {
      imageSrc: '/zg.jpg',
      quote:
        "Meraki Essentials is immensely impressed with Modaela Bags Manufacturing's quality and innovative designs. Their skill in embodying our brand essence into beautifully crafted bags has greatly contributed to our success. The Modaela team's professionalism and creativity have been outstanding, enhancing our brand image. We highly recommend Modaela for top-tier, customized bag solutions",
      customerName: 'Zero Gravity',
    },
    {
      imageSrc: '/orphic.jpg',
      quote:
        "Bayer's partnership with Modaela Bags Manufacturing has significantly elevated our brand. Their bespoke bags, blending functionality and style, reflect our commitment to quality and innovation. Modaela's professionalism and meticulous attention to detail have set a new standard, making their bags a symbol of our brand's excellence",
      customerName: 'Orphic',
    },
    {
      imageSrc: '/toto-george.jpg',
      quote:
        "Meraki Essentials is immensely impressed with Modaela Bags Manufacturing's quality and innovative designs. Their skill in embodying our brand essence into beautifully crafted bags has greatly contributed to our success. The Modaela team's professionalism and creativity have been outstanding, enhancing our brand image. We highly recommend Modaela for top-tier, customized bag solutions",
      customerName: 'Toto & George',
    },
    {
      imageSrc: '/alphabet.jpg',
      quote:
        "Bayer's partnership with Modaela Bags Manufacturing has significantly elevated our brand. Their bespoke bags, blending functionality and style, reflect our commitment to quality and innovation. Modaela's professionalism and meticulous attention to detail have set a new standard, making their bags a symbol of our brand's excellence",
      customerName: 'Alphabet Co.',
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {/* Dynamically create rows for testimonials */}
        {Array(Math.ceil(testimonials.length / 3))
          .fill()
          .map((_, rowIndex) => (
            <TestimonialsRow key={rowIndex}>
              {testimonials.slice(rowIndex * 3, rowIndex * 3 + 3).map((testimonial, index) => (
                <TestimonialContainer key={index}>
                  <Testimonial>
                    <LazyImage src={testimonial.imageSrc} alt="Testimonial" />
                    {/* <Quote>{testimonial.quote}</Quote> */}
                    <CustomerName>{testimonial.customerName}</CustomerName>
                  </Testimonial>
                </TestimonialContainer>
              ))}
            </TestimonialsRow>
          ))}
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
